<template>
    <PromiseContainer :promises.sync="computedPromises">
        <v-autocomplete :item-value="itemValue" multiple v-model="modelValue" :items="items" v-on="$listeners" v-bind="$attrs">
            <template v-slot:prepend-item>
                <v-list-item ripple @click="toggleAll" @mousedown.prevent>
                    <v-list-item-action>
                        <v-icon>{{ allIcon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Selecteer alle</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2" />
            </template>
        </v-autocomplete>
    </PromiseContainer>

</template>
<script>
import PromiseContainer from "@/components/shared/PromiseContainer.vue"

export default {
    name: "AutoCompleteWithSelectAll",
    model: {
        prop: 'value',
        event: 'update'
    },
    components: {
        PromiseContainer
    },
    props: {
        itemValue: String,
        promises: Array,
        value: Array,
        items: { type: Array, required: true }
    },
    emits: ['update'],
    methods: {
        toggleAll() {
            this.$nextTick(() => {
                if (this.hasChosenAllItems) {
                    this.modelValue = []
                } else {
                    if (this.itemValue != null) {
                        this.modelValue = this.items.map(item => item[this.itemValue])
                    } else {
                        this.modelValue = this.items
                    }
                }
            })
        }
    },
    computed: {
        computedPromises: {
            get() {
                return this.promises
            },
            set(newValue) {
                this.$emit("update:promises", newValue)
            },
        },
        modelValue: {
            get() {
                return this.value
            },
            set(newValue) {
                this.$emit('update', newValue)
            }
        },
        hasChosenAllItems() {
            return this.modelValue && this.modelValue.length === this.items.length
        },
        hasChosenItems() {
            return this.modelValue && this.modelValue.length > 0 && !this.hasChosenAllItems
        },
        allIcon() {
            if (this.hasChosenAllItems) return 'mdi-close-box'
            if (this.hasChosenItems) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    }
}
</script>